<template>
  <div>
    <v-select
      :items="trailerConditionOptions"
      v-model="trailerCondition"
      label="Trailer Condition"
    ></v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import appointmentService from '../services/appointmentService';

export default {
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    trailerConditionOptions() {
      return appointmentService.getTrailerConditionOptions();
    },
    trailerCondition: {
      get() {
        if (this.selectedAppointment.trailerCondition) {
          return this.selectedAppointment.trailerCondition;
        }
        return '';
      },
      set(trailerCondition) {
        this.setTrailerCondition(trailerCondition);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setTrailerCondition']),
  },
};
</script>
