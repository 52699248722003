<template>
  <div>
    <v-text-field class="pt-1 mt-0"
      v-model="customerName"
      v-bind:rules="requiredRule"
      label="Customer *"
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    requiredRule: [rules.rules.required],
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    customerName: {
      get() {
        if (this.selectedAppointment.customer) {
          return this.selectedAppointment.customer.name;
        }
        return '';
      },
      set(customerName) {
        this.setCustomerName(customerName);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setCustomerName']),
  },
};
</script>
