<template>
  <v-card class="text-center">
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col class="align-center">
          <h2>
            Are you sure you want to cancel this appointment?
          </h2>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col class="align-center">
          <v-btn class="mx-4" min-width="7vw" color="secondary" @click="yesButtonClicked"> Yes </v-btn>
          <v-btn class="mr-4" min-width="7vw" @click="cancelDenied"> No </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    ...mapGetters('account', ['fullName']),
  },
  methods: {
    ...mapActions('appointment', ['setShowCancelConfirmation', 'cancelAppointment']),
    yesButtonClicked() {
      const request = {
        appointment: this.selectedAppointment,
        deletedBy: this.fullName,
      };
      this.cancelAppointment(request);
      this.$emit('appointmentCanceled');
    },
    cancelDenied() {
      this.setShowCancelConfirmation(false);
    },
  },
};
</script>
