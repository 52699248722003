<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="sealNumber"
        label="Seal Number"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-checkbox
        v-model="isSealIntact"
        label="Seal Intact"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    sealNumber: {
      get() {
        if (this.selectedAppointment.sealNumber) {
          return this.selectedAppointment.sealNumber;
        }
        return '';
      },
      set(sealNumber) {
        this.setSealNumber(sealNumber);
      },
    },
    isSealIntact: {
      get() {
        return this.selectedAppointment.isSealIntact;
      },
      set(IsIntact) {
        this.setIsSealIntact(IsIntact);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setSealNumber', 'setIsSealIntact']),
  },
};
</script>
