<template>
<div>
    <appointment></appointment>
</div>
</template>

<script>
import Appointment from '../components/appointment.vue';

export default {
  name: 'AppointmentView',
  components: { Appointment },
};
</script>
