<template>
  <div>
    <v-text-field class="pt-1 mt-0"
      id="carrierName"
      v-model="carrierName"
      v-bind:rules="requiredRule"
      label="Carrier *"
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    requiredRule: [rules.rules.required],
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    carrierName: {
      get() {
        if (!this.selectedAppointment.equipment || !this.selectedAppointment.equipment.carrier) {
          return '';
        }
        return this.selectedAppointment.equipment.carrier.name;
      },
      set(carrierName) {
        this.setCarrierName(carrierName);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setCarrierName']),
  },
};
</script>
