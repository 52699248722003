<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="locationDisplayName"
        label="Spot Number"
        disabled
      ></v-text-field>
    </v-col>
    <v-col>
      <v-btn
        color="primary"
        style="color: white"
        v-on:click="findSpotClicked"
        :disabled="!hasEditPermissions"
      >
        Find a spot
      </v-btn>
      <spot-selection @spot-selected="spotSelected" selectionType="full" :shouldApplyColors="false" />
    </v-col>
    <v-col>
      <v-checkbox
        v-model="isPriority"
        label="Priority"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpotSelection from './spotSelectionDialog.vue';

export default {
  components: { SpotSelection },
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    ...mapGetters('account', ['isAdmin', 'isDeveloper']),
    locationDisplayName: {
      get() {
        if (this.selectedAppointment.location.displayName) {
          return this.selectedAppointment.location.displayName;
        }
        return '';
      },
      set(locationDisplayName) {
        this.setLocationDisplayName(locationDisplayName);
      },
    },
    isPriority: {
      get() {
        return this.selectedAppointment.isPriority;
      },
      set(isPriority) {
        this.setPriority(isPriority);
      },
    },
    hasEditPermissions() {
      return this.isAdmin || this.isDeveloper;
    },
  },
  methods: {
    ...mapActions('appointment', ['setLocationDisplayName', 'setLocationId', 'setPriority']),
    ...mapActions('location', ['setShowSpotSelectionDialog']),
    findSpotClicked() {
      this.setShowSpotSelectionDialog(true);
    },
    spotSelected(location) {
      this.setLocationId(location.id);
      this.setLocationDisplayName(location.displayName);
    },
  },
};
</script>
