<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="name"
        label="Driver Name"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        v-model="phone"
        label="Phone Number"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-select
        v-model="service"
        :items="serviceProviderOptions"
        label="Service Provider"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import appointmentService from '../services/appointmentService';

export default {
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    serviceProviderOptions() {
      return appointmentService.getServiceProviderOptions();
    },
    name: {
      get() {
        if (this.selectedAppointment.driver) {
          return this.selectedAppointment.driver.name;
        }
        return '';
      },
      set(name) {
        this.setDriverName(name);
      },
    },
    phone: {
      get() {
        if (this.selectedAppointment.driver) {
          return this.selectedAppointment.driver.phone;
        }
        return '';
      },
      set(phone) {
        this.setDriverPhone(phone);
      },
    },
    service: {
      get() {
        if (this.selectedAppointment.driver) {
          return this.selectedAppointment.driver.service;
        }
        return '';
      },
      set(service) {
        this.setDriverService(service);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setDriverName', 'setDriverPhone', 'setDriverService']),
  },
};
</script>
