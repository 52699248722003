<template>
  <v-row>
    <v-col cols="4">
      <v-menu
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          >
          <template v-slot:label>
            Start Date <span class="red--text">*</span>
          </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="4">
      <v-select
        v-model="startTime"
        :items="selectableStartTimes"
        item-text="formattedTime"
        item-value="date"
        item-disabled="isDisabled"
        label="Start Time"
        @change="endTime = getEndTime()"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        v-model="endTime"
        :items="selectableEndTimes"
        item-text="formattedTime"
        item-value="date"
        item-disabled="isDisabled"
        label="End Time"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    startDate: {
      get() {
        if (!this.selectedAppointment.startDate) {
          return '';
        }
        const formattedDate = this.selectedAppointment.startDate.toLocaleDateString('en-CA');
        return formattedDate;
      },
      set(val) {
        this.setStartDate(new Date(val.replace('-', '/')));
      },
    },
    startTime: {
      get() {
        if (this.selectedAppointment.startTime) {
          return this.selectedAppointment.startTime;
        }

        return this.availableTimes[0].date;
      },
      set(val) {
        this.setStartTime(val);
      },
    },
    endTime: {
      get() {
        if (this.selectedAppointment.endTime) {
          return this.selectedAppointment.endTime;
        }

        return this.getEndTime();
      },
      set(val) {
        this.setEndTime(val);
      },
    },
    availableTimes() {
      if (!this.selectedAppointment.startDate) {
        return [];
      }
      const formattedStartDate = new Date(this.startDate.replace('-', '/'));
      const times = this.$store.getters['appointment/availableTimes'](formattedStartDate);
      return this.mapTimes(times);
    },
    selectableStartTimes() {
      if (this.availableTimes.length === 1) {
        return this.availableTimes;
      }

      const selectableTimes = this.availableTimes.slice(0, -1);
      return selectableTimes;
    },
    selectableEndTimes() {
      const selectableTimes = _.cloneDeep(this.availableTimes);

      const startTimeIndex = selectableTimes.findIndex((w) => w.date.hour === this.startTime.hour && w.date.minute === this.startTime.minute);
      const selectableTimesBeforeStartTime = selectableTimes.slice(0, startTimeIndex + 1);

      selectableTimesBeforeStartTime.forEach((item) => {
        item.isDisabled = true;
      });

      return selectableTimes;
    },
  },
  methods: {
    ...mapActions('appointment', ['setStartDate', 'setStartTime', 'setEndTime']),
    getEndTime() {
      const startTimeIndex = this.availableTimes.findIndex((w) => w.date.hour === this.startTime.hour && w.date.minute === this.startTime.minute);
      return this.availableTimes[startTimeIndex + 1].date;
    },
    mapTimes(times) {
      return times.map((time) => {
        const date = new Date(time.date);
        return {
          date: {
            hour: date.getHours(),
            minute: date.getMinutes(),
          },
          isDisabled: time.isFull,
          formattedTime: time.date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
        };
      });
    },
  },
};
</script>
