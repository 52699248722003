<template>
  <div>
    <v-text-field class="pt-1 mt-0"
      v-model="comments"
      label="Comments"
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    comments: {
      get() {
        if (this.selectedAppointment.comments) {
          return this.selectedAppointment.comments;
        }
        return '';
      },
      set(comments) {
        this.setComments(comments);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setComments']),
  },
};
</script>
