<template>
  <div>
    <h3>{{ label }}</h3>
    <v-radio-group
      class="pt-0 pb-0"
      v-model="selectedValue"
      v-bind:rules="radioRules"
      row>
      <v-radio
        v-for="option in options"
          :key="option"
          :label="option"
          :value="option"
      >
        {{ option }}
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    radioRules: [
      rules.rules.required,
    ],
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    selectedValue: {
      get() {
        return this.selectedAppointment[this.property];
      },
      set(newValue) {
        this.$store.dispatch(this.action, newValue);
      },
    },
  },
};
</script>
